<template>
    <div style="text-align: left;font-size: 12px;">
        <el-card>
            <h2 class="text-center m-b-50">创建优惠券</h2>
            <el-form ref="form" :rules="rules" :model="form" label-width="100px">
                <el-row v-if="0">
                    <el-col :span="12">
                        <el-form-item label="优惠类型">
                            <el-select v-model="form.ticketKind" placeholder="请选择">
                                <el-option v-for="item in Kinds" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="form.name" placeholder="￥请输入用券最低订单金额"></el-input> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="0">
                        <el-form-item label="赠送方式">
                            <el-select v-model="value" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-input v-model="form.name" placeholder="￥请输入用券最低订单金额"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="优惠券名称" prop="">
                            <div class="c-1" v-if="ticketName">{{ticketName}}</div>
                            <!-- <el-input v-model="form.ticketName " type="text" placeholder="请输入优惠券名称" maxlength="8"></el-input> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="消费金额" prop="ticketLimitFee">
                            <el-input v-model="form.ticketLimitFee" type="number" placeholder="￥请输入用券最低订单金额"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="抵扣金额" prop="ticketFee">
                            <el-input v-model="form.ticketFee" type="number" placeholder="￥请输入用券最低订单金额"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="券库存量" prop="ticketPubTotal">
                            <!-- <el-input v-model="form.ticketPubTotal" type="number" placeholder="请输入券可被领取总量"></el-input> -->
                            <el-input-number v-model="form.ticketPubTotal" :min="1" :max="100000" label="描述文字"></el-input-number><span class="m-l-10 c-2">可领取的券数量</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="领券限制" prop="ticketEachLimit">
                            <el-input-number v-model="form.ticketEachLimit" :min="1" :max="100000" label="描述文字"></el-input-number><span class="m-l-10 c-2">每人可领取的券数量</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="生效时间" prop="ticketEffectiveTime">
                            <el-date-picker v-model="form.ticketEffectiveTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="失效时间" prop="ticketDeadTime">
                            <el-date-picker v-model="form.ticketDeadTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="适用范围">
                    <el-radio-group v-model="form.ticketScope">
                        <el-radio :label="0">全店通用</el-radio>
                        <el-radio :label="1">店铺分类</el-radio>
                        <el-radio :label="2">指定商品</el-radio>
                    </el-radio-group>
                    <div class="items" v-if="form.ticketScope==1">
                        <div v-for="(v,i) of byLtUserAuthAndClassifyCategoryList" :key="i" class="categoryItem item f12 pointer" :class="classify.indexOf(v.id)>-1?'select':''" @click="setSelect(v)">
                            <span>{{v.classifyName}}</span>
                            <img src="@/assets/images/stockCar/corner.png" class="corner">
                        </div>
                    </div>
                    <el-card shadow="never" style="margin-bottom: 25px;" v-if="form.ticketScope==2">
                        <div slot="header" class="my-header">
                            <div>产品列表 <el-input v-model="spuName" placeholder="请输入关键字搜索" size="mini" class="m-l-10" style="width: 300px;"></el-input>
                                <el-button type="default" size="mini" @click="search">搜索</el-button> <span v-if="multipleSelection && multipleSelection.length" class="c-2">已勾选：{{multipleSelection.length}}个商品</span>
                            </div>
                        </div>
                        <div>
                            <template>
                                <el-table :data="prlductData.content" style="width: 100%" @row-click="rowClick">
                                    <el-table-column width="55">
                                        <template slot-scope="scope">
                                            <el-checkbox v-model="true_" readonly="true" v-if="multipleSelection.indexOf(scope.row.id)>-1"></el-checkbox>
                                            <el-checkbox v-model="false_" readonly="true" v-else></el-checkbox>
                                            <div style="position:absolute;left:0;top: 0;height: 100%;width: 100%; z-index: 1000;"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column type="index" label="编号">
                                    </el-table-column>
                                    <el-table-column prop="date" label="产品封面" width="100">
                                        <template slot-scope="scope">
                                            <el-avatar shape="square" :src="scope.row.cover" v-if="hasImportImg(scope.row.cover)"></el-avatar>
                                            <el-avatar shape="square" :src="$oucy.ossUrl+scope.row.cover" v-else></el-avatar>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuName" label="产品名称/所属品牌">
                                        <template slot-scope="scope">
                                            <div>{{scope.row.spuName}}</div>
                                            <div class="c-2" v-if="scope.row.brand && scope.row.brand.brandName">{{scope.row.brand.brandName}}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="brand.brandName" label="所属分类">
                                        <template slot-scope="scope">
                                            <span v-for="item in byLtUserAuthAndClassifyCategoryList" v-if="scope.row.classify.id==item.id">{{item.classifyName}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="spuPrise" label="当前价格">
                                        <template slot-scope="scope">
                                            <span class="spuPrise" v-if="scope.row.spuPrise=='0.00'">面议</span>
                                            <span class="spuPrise" v-else>￥{{scope.row.spuPrise}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                            <div>
                                <el-divider></el-divider>
                                <div class="df-jc-s-b">
                                    <el-pagination v-if="prlductData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="prlductData.size" :total="prlductData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">创建</el-button>
                    <el-button type="" @click="$oucy.go('/enter/CouponManagement')">取消</el-button>
                    <!-- <el-button>保存</el-button> -->
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { Notification } from "element-ui";
import { furnitureClassify, spu, ticket } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"



export default {
    name: "Photo",
    data() {
        // 下面是验证的

        var validateTicketFee = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入抵扣金额'));
            } else if (this.form.ticketLimitFee && value * 1 > this.form.ticketLimitFee * 1) {
                console.log('this.form.ticketLimitFee', this.form.ticketLimitFee)
                console.log('value', value)
                callback(new Error('抵扣金额不能大于消费金额!'));
            } else if (value <= 0) {
                callback(new Error('抵扣金额需大于0!'));
            } else {
                callback();
            }
        };
        return {
            rules: {
                ticketName: [
                    { required: true, message: '请输入优惠券名称', trigger: 'blur' },
                    // { validator: validateticketLimitFee, trigger: 'blur' }
                ],
                ticketLimitFee: [
                    { required: true, message: '请输入消费金额', trigger: 'blur' },
                    // { validator: validateticketLimitFee, trigger: 'blur' }
                ],
                ticketFee: [
                    { required: true, message: '请输入抵扣金额', trigger: 'blur' },
                    { validator: validateTicketFee, trigger: 'blur' }
                ],
                ticketPubTotal: [
                    { required: true, message: '请输入券库存量', trigger: 'blur' },
                ],
                ticketEachLimit: [
                    { required: true, message: '请输入领券限制', trigger: 'blur' },
                ],
                ticketEffectiveTime: [
                    { required: true, message: '请选择生效时间', trigger: 'blur' },
                ],
                ticketDeadTime: [
                    { required: true, message: '请选择失效时间', trigger: 'blur' },
                ]
            },
            multipleSelection: [],
            prlductData: {},
            form: {
                ticketName: null,
                enterpriseId: null,
                "ticketCreatetime": "",
                "ticketDeadTime": "",
                "ticketEachLimit": null,
                "ticketEffectiveTime": "",
                "ticketEnterprise": "",
                "ticketFee": null,
                "ticketGetTotal": 10,
                "ticketIsPublish": true,
                "ticketKind": 0,
                "ticketLimitFee": null,
                "ticketPubTotal": null,
                "ticketScope": 0,
                "ticketUpdatetime": ""
            },
            Kinds: [{
                value: 0,
                label: '满减券'
            }, {
                value: 1,
                label: '现金券'
            }, ],
            classify: 3,
            value1: null,
            color1: null,
            checked: null,
            imageUrl: '',
            // 选中列表
            select: {},
            classify: [],
            // 店内分类列表
            byLtUserAuthAndClassifyCategoryList: [],
            // 指定
            spuName: null,
            timeoutID: null,
            limit: 10,
            start: 0,
            prlductData: {},

            classifyDatas: [],
            true_: true,
            false_: false,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        this.form.enterpriseId = enterprise.id
        // 查询分类
        this.findByLtUserAuthAndClassifyCategory()
        // 查询商品
        this.findAllFurnitureSpu()
        if (1 == 1) {
            this.form.ticketEffectiveTime = new Date().format('yyyy-MM-dd')
            this.form.ticketDeadTime = new Date().DateAdd('y', 1).format('yyyy-MM-dd')
        }
    },
    computed: {
        // 计算属性的 getter
        'ticketName': function() {
            // `this` 指向 vm 实例
            if (this.form.ticketLimitFee && this.form.ticketFee) {
                let  ticketName ='满' + this.form.ticketLimitFee + '减' + this.form.ticketFee + '券'
                this.form.ticketName=ticketName
                return ticketName
            } else
                return null
        }
    },
    methods: {
        handleClick() {},

        // 选择触发
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.ticketScope == 1) {
                        if (this.classify.length == 0) {
                            this.$alert('请先选择店铺分类')
                            return
                        }
                        this.form.classify = JSON.stringify(this.classify)
                        this.form.classifyJson = this.classify
                    } else {
                        this.form.classify = null
                    }
                    if (this.form.ticketScope == 2) {
                        if (this.multipleSelection.length == 0) {
                            this.$alert('请先选择指定商品选')
                            return
                        }
                        let spu = this.multipleSelection

                        this.form.spu = JSON.stringify(spu)
                        this.form.spuJson = spu
                    } else {
                        this.form.spu = null
                    }
                    this.form.ticketPubTotal = this.form.ticketPubTotal * 1
                    this.form.ticketEffectiveTime = new Date(this.form.ticketEffectiveTime).format('yyyy-MM-dd') + ' ' + '00:00:00'
                    this.form.ticketDeadTime = new Date(this.form.ticketDeadTime).format('yyyy-MM-dd') + ' ' + '23:59:59'
                    this.addCoupons()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        /**
         * 查询店内分类
         * @return {[type]} [description]
         */
        findByLtUserAuthAndClassifyCategory() {
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
                this.byLtUserAuthAndClassifyCategoryList = res
            })
        },
        // 选择分类
        setSelect(v) {
            let index = this.classify.indexOf(v.id)
            if (index > -1) {
                this.classify.splice(index, 1)
            } else {
                this.classify.push(v.id)
            }
            // this.select = v
        },
        rowClick(row, column, event) {
            console.log(1)
            // this.timeoutID && clearTimeout(this.timeoutID)
            // this.timeoutID =setTimeout(()=>{
            this.setSput(row)
            // },500)
        },
        // 选择商品
        setSput(v) {
            let index = this.multipleSelection.indexOf(v.id)
            if (index > -1) {
                this.multipleSelection.splice(index, 1)
            } else {
                this.multipleSelection.push(v.id)
            }
            // this.select = v
        },

        hasImportImg(v) {
            return v.startsWith('//')
        },
        // 产品列表
        // 
        search() {
            // this.limit=10
            this.start = 0
            this.findAllFurnitureSpu()
        },
        sizeChange: function(limit) {
            const me = this;
            me.limit = limit;
            me.findAllFurnitureSpu();
        },
        currentChange: function(current) {
            const me = this;
            me.start = current - 1;
            me.findAllFurnitureSpu();
        },
        // 查询商品
        findAllFurnitureSpu() {
            // /spu/findAllFurnitureSpuClient
            spu.findAllFurnitureSpu({
                spuName: this.spuName,
                limit: this.limit,
                start: this.start,
                isShelves: true,
                spuAuditStatus: 2,
                enterpriseId: this.enterpriseId
            }).then(res => {
                console.log(res)
                this.prlductData = res
            });
        },
        addCoupons() {
            ticket.addCoupons(this.form).then(res => {
                this.$message('创建成功')
                this.$oucy.go('/enter/CouponManagement')
                console.log(res)
            })
        },
    }

}
</script>
<style scoped lang="less">
.items {
    display: flex;
}

.item {
    padding: 4px 20px;
    line-height: 1.2;
    background: #FFFFFF;
    border: 1px solid #2090FF;
    border-radius: 2px;
    position: relative;
    border: 1px solid #EAEAEA;

    &.select {
        border: 1px solid #2090FF;

        .corner {
            display: inline-block;
        }
    }
}

.corner {
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.categoryItem+.categoryItem {
    margin-left: 10px;
}
</style>